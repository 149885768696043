<template>
  <div id="linear" style="height: 230px; width: 100%"></div>
</template>

<script>
import * as echarts from 'echarts'
var linearChart
export default {
  name: 'assetLinear',
  props: {
    data: {
      required: true,
      type: Array
    },
    option: {
      type: Number,
      required: true
    },
    val: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sortData: this.data, // 分类统计-数量
      sortMoneyData: this.data, // 分类统计-金额
      positionData: this.data, // 位置统计-数量
      positionMoneyData: this.data, // 位置统计-金额
      useData: this.data, // 使用部门统计-数量
      useMoneyData: this.data // 使用部门统计-数金额
    }
  },
  mounted() {
    this.init(this.option, this.val)
    window.addEventListener('resize', this.resize)
  },
  watch: {
    data() {
      this.sortData = this.data
      this.init(this.option, this.val)
    }
  },
  methods: {
    init(option, val) {
      console.log('option', option)
      // console.log('val', val)
      let data = []
      if (option === 1 && val === 'number') {
        data = this.sortData
      } else if (option === 1 && val === 'money') {
        data = this.sortMoneyData
      } else if (option === 2 && val === 'number') {
        data = this.positionData
      } else if (option === 2 && val === 'money') {
        data = this.positionMoneyData
      } else if (option === 3 && val === 'number') {
        data = this.useData
      } else if (option === 3 && val === 'money') {
        data = this.useMoneyData
      }
      // var linearChart
      // if (linearChart != null && linearChart != '' && linearChart != undefined) {
      //   linearChart.dispose()
      // }
      // 基于准备好的dom，初始化echarts电压实例
      linearChart = echarts.init(document.getElementById('linear'), 'light')
      // 绘制图表
      linearChart.setOption({
        // legend: {},
        // 提示框组件
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '10px',
          bottom: '10px',
          top: '10px',
          right: '15px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: [
            '土地、房屋及构筑物',
            '通用设备',
            '专用设备',
            '文物和陈列品',
            '图书、档案',
            '家具、用具、装具及动植物'
          ]
        },
        color: ['#6395f9'],
        barWidth: '40%',
        series: [
          {
            name: '分类统计',
            type: 'bar',
            min: 0,
            max: 10,
            interval: 2.5,
            data: data
          }
        ]
      })
    },
    handleChange(option, tip) {
      this.init(option, tip)
      console.log('子linearhandleChange', option)
    },
    tipsChange(val, tip) {
      this.init(val, tip)
      console.log('子页面数量和金额-Val', val)
      console.log('子页面数量和金额-tip', tip)
    },
    resize() {
      linearChart.resize()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
